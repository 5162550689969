// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, DatabaseTable, AlgebraicValue, ReducerArgsAdapter, SumTypeVariant, Serializer, Identity, Address, ReducerEvent, Reducer, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class AddFolderReducer extends Reducer
{
	public static reducerName: string = "AddFolder";
	public static call(_name: string, _icon: string) {
		this.getReducer().call(_name, _icon);
	}

	public call(_name: string, _icon: string) {
		const serializer = this.client.getSerializer();
		let _nameType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		serializer.write(_nameType, _name);
		let _iconType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		serializer.write(_iconType, _icon);
		this.client.call("AddFolder", serializer);
	}

	public static deserializeArgs(adapter: ReducerArgsAdapter): any[] {
		let nameType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		let nameValue = AlgebraicValue.deserialize(nameType, adapter.next())
		let name = nameValue.asString();
		let iconType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		let iconValue = AlgebraicValue.deserialize(iconType, adapter.next())
		let icon = iconValue.asString();
		return [name, icon];
	}

	public static on(callback: (reducerEvent: ReducerEvent, _name: string, _icon: string) => void) {
		this.getReducer().on(callback);
	}
	public on(callback: (reducerEvent: ReducerEvent, _name: string, _icon: string) => void)
	{
		this.client.on("reducer:AddFolder", callback);
	}
}


export default AddFolderReducer
