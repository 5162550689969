// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, DatabaseTable, AlgebraicValue, ReducerArgsAdapter, SumTypeVariant, Serializer, Identity, Address, ReducerEvent, Reducer, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class AddLayoutWithIdReducer extends Reducer
{
	public static reducerName: string = "AddLayoutWithId";
	public static call(_id: number, _name: string, _active: boolean) {
		this.getReducer().call(_id, _name, _active);
	}

	public call(_id: number, _name: string, _active: boolean) {
		const serializer = this.client.getSerializer();
		let _idType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		serializer.write(_idType, _id);
		let _nameType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		serializer.write(_nameType, _name);
		let _activeType = AlgebraicType.createPrimitiveType(BuiltinType.Type.Bool);
		serializer.write(_activeType, _active);
		this.client.call("AddLayoutWithId", serializer);
	}

	public static deserializeArgs(adapter: ReducerArgsAdapter): any[] {
		let idType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		let idValue = AlgebraicValue.deserialize(idType, adapter.next())
		let id = idValue.asNumber();
		let nameType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		let nameValue = AlgebraicValue.deserialize(nameType, adapter.next())
		let name = nameValue.asString();
		let activeType = AlgebraicType.createPrimitiveType(BuiltinType.Type.Bool);
		let activeValue = AlgebraicValue.deserialize(activeType, adapter.next())
		let active = activeValue.asBoolean();
		return [id, name, active];
	}

	public static on(callback: (reducerEvent: ReducerEvent, _id: number, _name: string, _active: boolean) => void) {
		this.getReducer().on(callback);
	}
	public on(callback: (reducerEvent: ReducerEvent, _id: number, _name: string, _active: boolean) => void)
	{
		this.client.on("reducer:AddLayoutWithId", callback);
	}
}


export default AddLayoutWithIdReducer
